@use '../partials/' as *;

.post-card {
  background-color: white;
  margin: 0.4rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.4rem #8800ff59;
  transform: scale(0.97);
  transition: all 0.3s ease;
  height: 100%;
  &:hover {
    transform: scale(1);
  }
  // Post Image
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 15px;
  }
  // Post Title
  h1 {
    color: darken($theme-color, 10%);
    font-size: 1.1rem;
  }
  // Post Content
  .preview {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 85%;
  }
  // Post Time
  .time {
    font-size: 12px;
    color: gray;
    font-weight: bold;
  }
  // Adding Media Queries
  @include responsive(mobile) {
    margin: 0 !important;
  }
}
