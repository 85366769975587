@use '../partials/' as *;

// Image Upload Loading
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-left: -5px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    border: 3px solid #8800ff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #8800ff transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
    @include keyframes(lds-ring) {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

// Loading (Home Page)
.spinner {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 100px auto;
  @include keyframes(sk-bounce) {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
  .double-bounce1 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #8800ff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    @include animation(sk-bounce, 2s, ease-in-out);
  }
  .double-bounce2 {
    @extend .double-bounce1;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
}

// Loading Two (Details Page)
.bounce {
  margin: 0 auto;
  margin-top: 2rem;
  width: 70px;
  text-align: center;
  div {
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 100%;
    display: inline-block;
    @include animation(sk-bouncedelay, 1.4s, ease-in-out, both);
  }
  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@include keyframes(sk-bouncedelay) {
  0%,
  80%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
