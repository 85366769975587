@use './mixins' as m;

$spaceamounts: (1, 2, 3, 4, 5);

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}rem;
  }
  .mr-#{$space} {
    margin-right: #{$space}rem;
  }
  .ml-#{$space} {
    margin-left: #{$space}rem;
  }
  .mt-#{$space} {
    margin-top: #{$space}rem;
  }
  .mb-#{$space} {
    margin-bottom: #{$space}rem;
  }
  .my-#{$space} {
    margin: #{$space}rem 0;
  }
  .p-#{$space} {
    padding: #{$space}rem;
  }
  .py-#{$space} {
    padding: #{$space}rem 0;
  }
}
.flex-center {
  @include m.flex-center(row, center);
}
